<template>

<div class="charities">

	<com-inner>

		<div class="charities-text">{{ block.text }}</div>

		<div class="charities-list">

			<div class="charities-list-item" :data-charity="charity" v-for="charity in charities" :key="charity">

				<router-link :to="$link('', charity, true)"  class="charities-list-item-logo"></router-link>

				<div class="charities-list-item-text">{{ block.charity[charity].text }}</div>

				<div class="charities-list-item-image" v-bgimage="block.charity[charity].image" />

				<router-link :to="$link('', charity, true)" class="charities-list-item-button">Visit</router-link>

			</div>

		</div>

	</com-inner>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	data: function() {

		return {
			charities: ['stars', 'aa', 'afa']
		}

	}

}

</script>

<style scoped>

.charities {
	background-image: url(~@/assets/landing.jpg);
	background-position: 50% 50%;
	background-size: cover;
	padding: 50px 0px 40px 0px;
}

.is-mobile .charities {
	padding: 20px 0px;
}

.is-embed .charities {
	margin-left: -20px;
	margin-top: -20px;
	margin-bottom: -20px;
	width: calc(100% + 40px);
}

.charities-text {
	text-align: center;
	color: #fff;
	font-size: 26px;
	font-weight: bold;
}

.is-mobile .charities-text {
	font-size: 18px;
}

.charities-list {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 131px;
}

.is-device .charities-list {
	flex-direction: column;
}

.is-mobile .charities-list {
	margin-top: 70px;
}

.charities-list-item {
	width: 349px;
	margin: 0px 10px;
	border-radius: 23px;
}

.is-device .charities-list-item {
	width: calc(100% - 40px);
	margin: 10px 20px 100px 20px;
}

.is-mobile .charities-list-item {
	width: 100%;
	margin: 0px 0px 60px 0px;
}

.is-device .charities-list-item:last-child {
	margin-bottom: 20px;
}

.charities-list-item[data-charity="stars"] {
	background-color: #1277d4;
}

.charities-list-item[data-charity="aa"] {
	background-color: #c21920;
}

.charities-list-item[data-charity="afa"] {
	background-color: #5ca043;
}

.charities-list-item-logo {
	width: 100%;
	height: 162px;
	display: block;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	margin-top: -81px;
}

.is-mobile .charities-list-item-logo {
	height: 80px;
	margin-top: -40px;
}

.charities-list-item[data-charity="stars"] .charities-list-item-logo {
	background-image: url(~@/assets/logo.stars.png);
}

.charities-list-item[data-charity="aa"] .charities-list-item-logo {
	background-image: url(~@/assets/logo.aa.png);
}

.charities-list-item[data-charity="afa"] .charities-list-item-logo {
	background-image: url(~@/assets/logo.afa.png);
}

.charities-list-item-text {
	text-align: center;
	color: #fff;
	font-size: 16px;
	line-height: 20px;
	height: 140px;
	padding: 20px;
	font-weight: bold;
}

.is-device .charities-list-item-text {
	height: auto;
}

.is-mobile .charities-list-item-text {
	font-size: 14px;
	font-weight: 300;
	padding: 10px;
}

.charities-list-item-image {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 161px;
}

.is-device .charities-list-item-image {
	display: none;
}

.charities-list-item-button {
	background-color: #1a234c;
	display: block;
	width: 120px;
	margin: 20px auto;
	cursor: pointer;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	border-radius: 18px;
	font-weight: 300;
	white-space: nowrap;
	height: 36px;
	line-height: 36px;
	padding: 0px 20px;
	font-size: 12px;
	letter-spacing: 1.2px;
}

.is-mobile .charities-list-item-button {
	margin: 10px auto;
}

</style>
